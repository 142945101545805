import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function CategoryTemplate({data}) {
//   const { markdownRemark } = data // data.markdownRemark holds your post data
//   const { frontmatter } = markdownRemark

    const Product = ({data, url}) => (
        <Link to={`${url}/${data.url}`} className="product-link">
            <img src={data.images[0].childImageSharp.large.src} alt={data.title}/>
            <h3 dangerouslySetInnerHTML={{__html: data.htmltitle}}></h3>
        </Link>
    )

    const ProductList = ()  => {

        return data.allMarkdownRemark.edges.map((e, i) =>  <Product data={e.node.frontmatter} key={"product-" + i} url={data.markdownRemark.frontmatter.url} />
        )
    }

   return (

        <Layout pageClass='category-page'>
        <SEO title={data.markdownRemark.frontmatter.title} />
            <main className='main'>
                <div className="header-image">
                   <img src={data.markdownRemark.frontmatter.headerimage.publicURL} />
                </div>
                <div className="inner">
                    <h1 dangerouslySetInnerHTML={{__html: data.markdownRemark.frontmatter.htmltitle}}></h1>
                

                    <div className="category-products">
                        <ProductList />
                    </div>
            
                </div>
            </main> 
        </Layout>
    
  )
}
export const pageQuery = graphql`
    query($url: String!) {
        markdownRemark(frontmatter: {type: {eq: "category"}, url: {eq: $url}}) {
            id
            frontmatter {
                title
                htmltitle
                url
                headerimage {
                    publicURL
                }
                
            }
          }
        allMarkdownRemark(filter: {frontmatter: {category: {eq: $url}}}) {
            edges {
                node {
                    frontmatter {
                        title
                        htmltitle
                        url
                        images {
                            childImageSharp {
                    
                                large: resize(width:400, height: 280) {
                                    src
                                }
                            }
                            publicURL 
                        }
                    }
                } 
            } 
        }
        
    }
  
`

// markdownRemark(frontmatter: { url: { eq: $url } }) {
//     frontmatter {
//         title
//         url
//         category
//         attributes
//         videos
//         images {
//             childImageSharp {
                
//                 thumbnail: resize(width:80, height: 80) {
//                     src
//                 }
//                 large: resize(width:400, height: 400) {
//                     src
//                 }
//             }
//             publicURL
//         }
        
//     }
// }


// allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativePath: {regex: $folder}}) {
//     edges {
//       node {
//           childImageSharp {
          
//               thumbnail: resize(width:80, height: 80) {
//                   src
//               }
//               large: resize(width:400, height: 400) {
//                   src
//               }
//           }
//       }
//     }
//   }